// src/components/RegistrationForm.js
import React, { useState } from 'react';
import sitElephantImage from '../../images/sitting_elephant.png';


function RegistrationForm() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [messageStyle, setMessageStyle] = useState({});
  const API_URL = process.env.REACT_APP_API_URL;

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${API_URL}/auth/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, email, password })
      });

      const data = await response.json();
      if (response.ok) {
        setMessage(data.message || 'Registration successful');
        setMessageStyle({ color: 'green' });
        setTimeout(() => {
          window.location.href = '/';
        }, 0);
      } else {
        setMessage(data.error || 'Error registering user');
        setMessageStyle({ color: 'red' });
      }
    } catch (error) {
      setMessage('Error connecting to the server');
      setMessageStyle({ color: 'red' });
    }
  };

  return (
  <div className="container p-4 mt-3" style={{ maxWidth: '400px', margin: 'auto' }}>
    <div className='col-md-6'>              
      <img
        src={sitElephantImage}
        alt="Blue Elephant"
        className="img-fluid mb-3"
        style={{ maxWidth: '100%', height: 'auto' }}
      />
    </div>
    <h2 className="mb-4 text-center">Register a New Account</h2>
    <form onSubmit={handleSubmit}>
      {/* Username Input */}
      <div className="mb-3">
        <label htmlFor="username" className="form-label">
          Username:
        </label>
        <input
          type="text"
          className="form-control"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
      </div>

      {/* Email Input */}
      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          Email:
        </label>
        <input
          type="email"
          className="form-control"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>

      {/* Password Input */}
      <div className="mb-3">
        <label htmlFor="password" className="form-label">
          Password:
        </label>
        <input
          type="password"
          className="form-control"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>

      {/* Submit Button */}
      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-primary"
          style={{
            padding: '10px 15px',
            backgroundColor: '#5aa1bd',
            borderColor: '#5aa1bd',
          }}
        >
          Register
        </button>
      </div>
    </form>

    {/* Message */}
    {message && <p className="mt-3 text-center" style={messageStyle}>{message}</p>}

    <div>    
    <div className="d-flex align-items-center my-3">
    <div className="flex-grow-1 border-top"></div>
    <span className="mx-2 text-muted">or</span>
    <div className="flex-grow-1 border-top"></div>
  </div>
    <a 
      className="btn btn-primary w-100 rounded-pill" 
      href='/'
      style={{
        padding: '10px 15px',
        backgroundColor: '#5aa1bd',
        borderColor: '#5aa1bd',
      }}
      >
        Already have an account?</a>
  </div>
  </div>
  );
}

export default RegistrationForm;
