import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, NavLink } from 'react-router-dom';
import axios from 'axios';
import HomePage from './pages/HomePage';
import RegistrationPage from './pages/RegistrationPage';
import DashboardPage from './pages/DashboardPage';
import HistoryPage from './pages/HistoryPage';
import AboutPage from './pages/AboutPage';
import SettingsPage from './pages/SettingsPage';
import Footer from './components/Footer';
import NavBar from './components/NavBar';


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);  
  const [theme, setTheme] = useState('light'); // Default theme is light

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsAuthenticated(true);
      fetchUserSettings(token); // Fetch user-specific settings
    } else {
      applyGuestTheme(); // Apply system theme for unauthenticated users
    }

    // Initialize WebSocket connection
    const webSocket = new WebSocket(
      window.location.hostname === 'localhost'
        ? 'ws://localhost:5000/ws'
        : `wss://${window.location.host}/ws`
    );
    // WebSocket event handlers
    webSocket.onopen = () => {
      console.log('WebSocket connection established');
    };
    webSocket.onmessage = (event) => {
      console.log('Received message:', event.data);
      // You can handle incoming messages here (e.g., update UI)
    };
    webSocket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
    webSocket.onclose = () => {
      console.log('WebSocket connection closed');
    };
    // Clean up WebSocket on component unmount
    return () => {
      if (webSocket.readyState === WebSocket.OPEN) {
        webSocket.close();
      }
    };
  }, []);

  const fetchUserSettings = async (token) => {
    try {
      const response = await axios.get('http://localhost:5000/api/auth/settings', {
        headers: { Authorization: `Bearer ${token}` },
      });
      const userMode = response.data.mode || 'light'; // Default to light if no mode is set
      setTheme(userMode);
    } catch (error) {
      console.error('Error fetching user settings:', error);
    }
  };

  const applyGuestTheme = () => {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    setTheme(prefersDark ? 'dark' : 'light'); // Apply system preference for unauthenticated users
  };

  useEffect(() => {
    // Dynamically update the body class based on the theme
    document.body.classList.toggle('dark-mode', theme === 'dark');
    document.body.classList.toggle('light-mode', theme === 'light');
  }, [theme]);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
    applyGuestTheme(); // Reapply guest theme on logout
  };

  return (
    <BrowserRouter>
      <div className="container p-0 d-flex flex-column min-vh-100">
        <div className="content">
          {isAuthenticated && (
            <NavBar
            isAuthenticated={isAuthenticated}
            setIsAuthenticated={setIsAuthenticated}
            handleLogout={handleLogout}
          />
          )}
          <Routes>
            <Route
              path="/"
              element={isAuthenticated ? <Navigate to="/dashboard" /> : <HomePage />}
            />
            <Route path="/registration" element={<RegistrationPage />} />
            <Route
              path="/dashboard"
              element={isAuthenticated ? <DashboardPage /> : <Navigate to="/" />}
            />
            <Route
              path="/history"
              element={isAuthenticated ? <HistoryPage /> : <Navigate to="/" />}
            />
            <Route
              path="/about"
              element={isAuthenticated ? <AboutPage /> : <Navigate to="/" />}
            />
            <Route
              path="/settings"
              element={isAuthenticated ? <SettingsPage /> : <Navigate to="/" />}
            />
          </Routes>    
        </div>  
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
