// src/components/LoginForm.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [messageStyle, setMessageStyle] = useState({});
  const [token, setToken] = useState('');
  const navigate = useNavigate(); // Use the hook for navigation
  const API_URL = process.env.REACT_APP_API_URL;

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${API_URL}/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Login successful');
        setMessageStyle({ color: 'green' });
        setToken(data.token); // Store the token (e.g., in localStorage or state)
        localStorage.setItem('authToken', data.token); // Optionally store the token in localStorage
        setTimeout(() => {
          window.location.href = '/dashboard';
        }, 0);
      } else {
        setMessage(data.error || 'Error logging in');
        setMessageStyle({ color: 'red' });
      }
    } catch (error) {
      setMessage('Error connecting to the server');
    }

    console.log('Stored Token:', localStorage.getItem('authToken'));
  };

  return (
<div className="p-4">
  <h2 className="mb-4">Login</h2>
  <form onSubmit={handleSubmit}>
    {/* Email Input */}
    <div className="mb-3">
      <label htmlFor="email" className="form-label">
        Email:
      </label>
      <input
        type="email"
        className="form-control"
        id="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
    </div>

    {/* Password Input */}
    <div className="mb-3">
      <label htmlFor="password" className="form-label">
        Password:
      </label>
      <input
        type="password"
        className="form-control"
        id="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
    </div>

    {/* Submit Button */}
    <div className="d-flex justify-content-end">
      <button
        type="submit"
        className="btn btn-primary"
        style={{
          padding: '10px 15px',
          backgroundColor: '#5aa1bd',
          borderColor: '#5aa1bd',
        }}
      >
        Login
      </button>
    </div>
  </form>

  {/* Message */}
  {message && <p className="mt-3 text-center" style={messageStyle}>{message}</p>}

  <div>    
    <div className="d-flex align-items-center my-3">
    <div className="flex-grow-1 border-top"></div>
    <span className="mx-2 text-muted">or</span>
    <div className="flex-grow-1 border-top"></div>
  </div>
    <a 
      className="btn btn-primary w-100 rounded-pill" 
      href='/registration'
      style={{
        padding: '10px 15px',
        backgroundColor: '#5aa1bd',
        borderColor: '#5aa1bd',
      }}
      >
        Create account</a>
  </div>
</div>
  );
}

export default LoginForm;
