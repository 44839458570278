import React, { useState } from 'react';
import axios from 'axios';

function TaskDashboardForm({ onSubmit }) {
  const [startDate, setStartDate] = useState('');
  const [duration, setDuration] = useState('');
  const [task1, setTask1] = useState('');
  const [task2, setTask2] = useState('');
  const [optionalTasks, setOptionalTasks] = useState([]); // Store optional tasks

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (!startDate) {
      alert('Start Date is required!');
      return;
    }
    if (!task1 || !task2) {
      alert('Task 1 and Task 2 are required!');
      return;
    }
    if (duration < 20 || duration > 31) {
      alert('Duration must be between 20 and 31 days.');
      return;
    }

    const formData = {
      startDate,
      duration,
      task1,
      task2,
      ...optionalTasks.reduce((acc, task, index) => {
        acc[`task${index + 3}`] = task;
        return acc;
      }, {}),
    };

    try {
      alert('Data successfully saved!');
      onSubmit(formData); 
    } catch (error) {
      console.error('Error sending data:', error);
      alert('An error occurred while saving data.');
    }
  };

  // Add an optional task field
  const addOptionalTask = () => {
    if (optionalTasks.length < 3) {
      setOptionalTasks((prevTasks) => [...prevTasks, '']);
    }
  };

  // Remove an optional task field
  const removeOptionalTask = (index) => {
    setOptionalTasks((prevTasks) => prevTasks.filter((_, i) => i !== index));
  };


  return (
    <form className="task-dashboard-form p-5" onSubmit={handleSubmit}>
      <div className="mb-3">
        <label className="form-label">Start Date:</label>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="form-control"
          required
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Duration (days between 20 and 31):</label>
        <div className="d-flex align-items-center">
          <input
            type="range"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            min="20"
            max="31"
            className="form-range"
            required
          />
          <span className="ms-3">{duration || 21}</span>
        </div>
      </div>

      <div className="mb-3">
        <label className="form-label">Task 1:</label>
        <input
          type="text"
          value={task1}
          onChange={(e) => setTask1(e.target.value)}
          className="form-control"
          required
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Task 2:</label>
        <input
          type="text"
          value={task2}
          onChange={(e) => setTask2(e.target.value)}
          className="form-control"
          required
        />
      </div>

      {/* Render Optional Task Fields */}
      {optionalTasks.map((task, index) => (
        <div key={index} className="mb-3">
          <label className="form-label">Task {index + 3}:</label>
          <div className="d-flex align-items-center">
            <input
              type="text"
              value={task}
              onChange={(e) => {
                const updatedTasks = [...optionalTasks];
                updatedTasks[index] = e.target.value;
                setOptionalTasks(updatedTasks);
              }}
              className="form-control me-2"
            />
            <button
              type="button"
              onClick={() => removeOptionalTask(index)}
              className="btn btn-danger btn-sm"
            >
              X
            </button>
          </div>
        </div>
      ))}

      {/* Add Task Button */}
      {optionalTasks.length < 3 && (
        <div className="mb-3">
          <button
            type="button"
            onClick={addOptionalTask}
            style={{
              padding: '5px 10px',
              backgroundColor: '#5aa1bd',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            + Add Task
          </button>
        </div>
      )}

      <div className="d-flex justify-content-end p-3">
        <button
          type="submit"
          style={{
            padding: '5px 10px',
            backgroundColor: '#26995a',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
          title="Submit"
        >
          <i className="bi bi-check-circle"></i> Create
        </button>
      </div>
    </form>
  );
}

export default TaskDashboardForm;
