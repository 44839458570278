import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TaskDashboardForm from '../components/forms/TaskDashboardForm';
import TaskRatingForm from '../components/forms/TaskRatingForm';

function DashboardPage() {
  const [formData, setFormData] = useState(null); // Stores the latest dashboard data
  const [tasks, setTasks] = useState([]); // All task dashboards
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false); // Controls the visibility of TaskDashboardForm

  const API_URL = process.env.REACT_APP_API_URL;

  // Fetch user-specific tasks on page load
  useEffect(() => {
    const fetchTasks = async () => {
      const token = localStorage.getItem('authToken'); // Get token from localStorage
      if (!token) {
        console.error('User not authenticated');
        return;
      }

      try {
        const response = await axios.get(`${API_URL}/task/user-data`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTasks(response.data); // Save tasks to state
        const activeTask = response.data.find((task) => task.isActive === 'true'); // Find active task
        if (activeTask) {
          setFormData(activeTask); // Set the active task as formData
        }
      } catch (error) {
        console.error('Error fetching tasks:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTasks();
  }, []);

  // Handle submission of new TaskDashboardForm
  const handleDashboardFormSubmit = async (data) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      console.error('User not authenticated');
      return;
    }

    try {
      // API call
      const response = await axios.post(`${API_URL}/task/form`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Update the new task with the response ID
      const newTask = { ...data, id: response.data.id, isActive: 'true' }; // Add `isActive` to the new task
      // Update state: deactivate old tasks and add the new one
      setTasks((prevTasks) =>
        prevTasks.map((task) => ({ ...task, isActive: 'false' })).concat(newTask)
      ); 
      setFormData(newTask); // Set the new task as the active formData
      setShowForm(false); // Hide the form after successful submission

      // alert('Form submitted successfully');
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form');
    }
  };

  return (
    <div className="App">
      <div className="m-3 h-buzzer-background d-flex flex-column justify-content-between" style={{ height: '100%' }}>
        {/* Title */}
        <div className="text-center">
          <h1 className="mt-5">Buzzer List</h1>
        </div>         

        {/* "Create New Dashboard" Button */}
        {formData && !showForm && (
          <div className="mt-auto d-flex justify-content-end">
            <button 
              onClick={() => setShowForm(true)} 
              style={{
                padding: '10px 20px',
                backgroundColor: '#5aa1bd',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              + Create New
            </button>
          </div>
        )}
      </div>
  
      {/* Show the form if there is no dashboard (first time) or if "Create New" is clicked */}
      {(!formData || showForm) && (
        <TaskDashboardForm
          onSubmit={handleDashboardFormSubmit}
          onCancel={() => setShowForm(false)} // Allow the user to cancel the form
        />
      )}
  
      {/* Show TaskRatingForm for the active dashboard if it exists and the form is not visible */}
      {formData && !showForm && <TaskRatingForm formData={formData} />}
    </div>
  );
}


export default DashboardPage;
