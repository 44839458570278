import React from 'react';
import LoginForm from '../components/forms/LoginForm';
import elephantImage from '../images/elephant.png';

function HomePage() {
  const scrollToLogin = () => {
    const loginSection = document.getElementById('login-side');
    if (loginSection) {
      loginSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
<div className="container homepage-container vh-100">
      <div className="row vh-100">
        {/* About Section */}
        <div className="col-sm-8 about-side">
          <div className='p-2'>
            {/* Scroll to Login Button for mobile */}
            <div className="d-sm-none text-end mt-2">
              <button 
                className="btn btn-primary" 
                style={{
                  padding: '10px 15px',
                  backgroundColor: '#5aa1bd',
                  borderColor: '#5aa1bd',
                }} 
                onClick={scrollToLogin}
              >
                Login
              </button>
            </div>
            <div className='row'>
              <div className='col-md-4 p-4 mt-3'>
                <h1 className="content-title mb-3">Blue Elephant:</h1>
                <h3>Your Journey to Productivity and Satisfaction</h3>
              </div>
              <div className='col-md-8'>              
                <img
                  src={elephantImage}
                  alt="Blue Elephant"
                  className="img-fluid mb-3"
                  style={{ maxWidth: '70%', height: 'auto' }}
                />
              </div>
            </div>
            <p className="">
                Welcome to <strong>Blue Elephant</strong> - an innovative tool designed to help you take charge of your days and boost your productivity. Inspired by the <strong>Buzzer-List</strong> concept from the renowned book <em>The End of Procrastination</em>, this app combines simplicity with effectiveness to transform your daily habits.
            </p>
            <h5 className="mt-5">
                <strong>Here's how it works:</strong>
            </h5>
            <ul className="mt-3">
                <li>Create a new <strong>Buzzer List</strong> by setting a start date and a duration (20-31 days).</li>
                <li>Define your <strong>core tasks</strong> - Task 1 and Task 2 are mandatory, but you can add up to 5 tasks for maximum flexibility.</li>
                <li>Track your progress: Each day, log whether you've completed your tasks with a simple visual system - a green dot for success, a black dot for incomplete.</li>
                <li>Reflect on your day: Rate your overall satisfaction from 1 (worst) to 10 (best) and gain insights into your performance over time.</li>
            </ul>
            <p className="mt-5">
                With <strong>Blue Elephant</strong>, every step forward is a step towards mastering your habits and unlocking your potential. Whether you're aiming for personal growth or professional excellence, this is your companion for a more fulfilling and productive life.
            </p>
            
            <a href="/registration" 
            className="btn btn-primary"
            style={{
              padding: '10px 15px',
              backgroundColor: '#5aa1bd',
              borderColor: '#5aa1bd',
            }}>Start your journey today!</a>

          </div>
        </div>

        {/* Login Section */}
        <div className="col-sm-4 login-side" id="login-side">
          <LoginForm />
        </div>
      </div>
    </div>
  );
}

export default HomePage;

