import React, { useState } from 'react';
import RegistrationForm from '../components/forms/RegistrationForm';

function RegistrationPage() {
  return (
    <div>      
      <RegistrationForm />
    </div>
  );
}

export default RegistrationPage;