import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import HomeImg from '../images/nav/home.png';
import HistoryImg from '../images/nav/history.png';
import SettingsImg from '../images/nav/settings.png';
import AboutImg from '../images/nav/about.png';
import LogoutImg from '../images/nav/logout.png';

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
}

const NavBar = ({ handleLogout }) => {
  const isMobile = useIsMobile();

  return (
    <nav className="p-3">
      <ul className="nav nav-tabs card-header-tabs nav-justified">
        <li className="nav-item">
          <NavLink
            className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
            to="/dashboard"
          >
            {isMobile ? (
              <img src={HomeImg} alt="Home" style={{ height: '24px' }} />
            ) : (
              'Home'
            )}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
            to="/history"
          >
            {isMobile ? (
              <img src={HistoryImg} alt="History" style={{ height: '24px' }} />
            ) : (
              'History'
            )}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
            to="/settings"
          >
            {isMobile ? (
              <img src={SettingsImg} alt="Settings" style={{ height: '24px' }} />
            ) : (
              'Settings'
            )}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
            to="/about"
          >
            {isMobile ? (
              <img src={AboutImg} alt="About" style={{ height: '24px' }} />
            ) : (
              'About'
            )}
          </NavLink>
        </li>
        <li className="nav-item">
          {isMobile ? (
            <div className="nav-link">
            <img
              src={LogoutImg}
              alt="Logout"
              style={{ height: '24px', cursor: 'pointer' }}
              onClick={handleLogout}
            /></div>
          ) : (
            <button className="nav-link" onClick={handleLogout}>
              Logout
            </button>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
