import React from 'react';
import elephantImage from '../images/calendar_elephant.png';

const AboutPage = () => {
  return (
    <div className="about-page container p-5 mt-3">
        <h1 className="mb-4">What do you need know about Blue Elephant</h1>
        <p>
            The Buzzer List is designed to help you track your daily habits and stay focused on your goals. By filling it out every day, you're creating a simple but powerful routine that keeps you accountable and motivates you to take action.
        </p>
        <p>
            Here's why it's important to stick with it for at least 20 days:
        </p>
        <div className='row'>
            <div className='col-md-4'>
                <img
                    src={elephantImage}
                    alt="Blue Elephant"
                    className="img-fluid mb-3"
                    style={{ maxWidth: '100%', height: 'auto' }}
                    />
                </div>
            <div className='col-md-8'>  
                <ul>
                    <li><strong>Build Consistency:</strong> Completing the list daily helps form a habit. The more consistent you are, the more natural it becomes to follow through with your tasks.</li>
                    <li><strong>Stay Accountable:</strong> Each day, you mark off tasks with a green dot if you've done them, or a black dot if you haven't. This gives you immediate feedback on your progress and helps you stay on track.</li>
                    <li><strong>Reflect and Improve:</strong> Rating your day on a scale from 1 to 10 lets you reflect on your progress and satisfaction. Over time, you can see patterns in your performance and use that insight to improve.</li>
                </ul>
            </div>
            <p>
                By filling out the Buzzer List daily for at least 20 days, you're giving yourself a clear path to break procrastination and build better habits that last. Stay consistent and watch how small daily actions lead to big changes!
            </p>
        </div>

    </div>
  );
};

export default AboutPage;
