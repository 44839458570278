import React, { useEffect, useState } from 'react';
import axios from 'axios';

const HistoryPage = () => {
  const [dashboards, setDashboards] = useState([]);
  const [error, setError] = useState('');
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchHistory = async () => {
      const token = localStorage.getItem('authToken'); 
      try {
        console.log('Fetching history from backend...');
        const response = await axios.get(`${API_URL}/task/history`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include JWT token
          },
        });
        console.log('Response from /history:', response.data);
        setDashboards(response.data);
      } catch (err) {
        console.error('Error fetching history:', err);
        setError('No history available.');
      }
    };

    fetchHistory();
  }, []);

    return (
  <div className="history-page mt-3">
    <h1 className="mt-5 text-center">History</h1>
    {error && <p className="text-center">{error}</p>}
    {dashboards.map((dashboard) => {
      const endDate = new Date(dashboard.startDate);
      const realEndDate = new Date(dashboard.startDate);
      realEndDate.setDate(realEndDate.getDate() + dashboard.realDuration);
      const displayEndDate = dashboard.realDuration !== dashboard.duration;

      return (
        <div
          className="history-dashboard p-4 m-4 shadow-sm"
          key={dashboard.id}
        >
          <div className="row">
            {/* Header with Dates */}
            <div className='col-md-6'>   
              <h2 className="mb-0">Buzzer List</h2>
              <p className="history-date mb-0">
                {dashboard.startDate} ... {realEndDate.toISOString().split('T')[0]}
              </p>
            </div>
            {/* Duration Info */}
            <div className='col-md-6'>
              {!displayEndDate && (
              <p className="text-end">
                <strong>Duration:</strong> {dashboard.duration} days
              </p>
              )}
              {displayEndDate && (
              <p className="text-end">
                <span className="text-decoration-line-through">Set Duration: {dashboard.duration} days</span><br></br>
                <strong>Real Duration:</strong> {dashboard.realDuration} days
              </p>
              )}
            </div>
          </div>          


          {/* Task Ratings */}
          <ul className="history-tasks list-unstyled m-0 text-center">
            {Object.keys(dashboard.averageRatings).map((taskKey, index) => {
              if (dashboard[taskKey.replace('_rating', '')]) {
                return (
                  <li key={index} className="mb-2">
                    {/* Task Name */}
                    <span className="">
                      {dashboard[taskKey.replace('_rating', '')]}:
                    </span>
                    {/* Percentage */}
                    <span className="ms-2">
                      {dashboard.averageRatings[taskKey] !== null
                        ? `${dashboard.averageRatings[taskKey].toFixed(2)}%`
                        : "N/A"}
                    </span>
                  </li>
                );
              }
              return null;
            })}
          </ul>

          {/* Average Day Rating */}
          <div className="d-flex justify-content-end align-items-center">
            <p className="align-items-center">
              <strong>Average Day Rating:</strong>
            </p>
            <p className="average-circle d-flex justify-content-center align-items-center p-2"
                >
              {dashboard.averageDayRating !== null
                ? dashboard.averageDayRating.toFixed(2)
                : "N/A"}
            </p>
          </div>
          
        </div>
      );
    })}
  </div>
  );
};

export default HistoryPage;
