import React from 'react';

const Footer = () => {
  return (
    <footer className="text-center py-3 d-none d-sm-block">
      <div className="container">
        <p className="mb-0 mt-3">
          &copy; {new Date().getFullYear()} BlueElephant. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
