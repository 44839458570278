import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toolElephantImage from '../images/tool_elephant.png';

const SettingsPage = () => {
  const [username, setUsername] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const [isEditingUsername, setIsEditingUsername] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isEditingPassword, setIsEditingPassword] = useState(false);

  const [mode, setMode] = useState('light');
  const [message, setMessage] = useState('');

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchSettings = async () => {
      const token = localStorage.getItem('authToken');

      try {
        const response = await axios.get(`${API_URL}/auth/settings`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setUsername(response.data.username);
        setMode(response.data.mode);
      } catch (err) {
        console.error('Error fetching user settings:', err);
        setMessage('Failed to fetch user settings.');
      }
    };

    fetchSettings();
  }, []);

  // Handle username save
  const handleUsernameSave = async () => {
    const token = localStorage.getItem('authToken');

    try {
      const response = await axios.put(
        `${API_URL}/auth/settings`,
        { username: newUsername || username, mode },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setUsername(newUsername || username);
      setNewUsername('');
      setIsEditingUsername(false);
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response?.data?.error || 'Failed to update username.');
    }
  };

  // Handle password save
  const handlePasswordSave = async () => {
    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match!');
      return;
    }

    const token = localStorage.getItem('authToken');

    try {
      const response = await axios.put(
        `${API_URL}/auth/settings/password`,
        { currentPassword, newPassword },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setMessage(response.data.message);
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setIsEditingPassword(false);
    } catch (error) {
      setMessage(error.response?.data?.error || 'Failed to update password.');
    }
  };

  // Handle dark mode toggle
  const handleModeToggle = async (newMode) => {
    const token = localStorage.getItem('authToken');

    try {
      const response = await axios.put(
        `${API_URL}/auth/settings`,
        { username, mode: newMode },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setMode(newMode);
      document.body.classList.toggle('dark-mode', newMode === 'dark');
    } catch (error) {
      setMessage(error.response?.data?.error || 'Failed to update mode.');
    }
  };

  return (
    <div className="container settings-page">
      <img
        src={toolElephantImage}
        alt="Blue Elephant"
        className="img-fluid mb-3"
        style={{ maxWidth: '100%', height: 'auto' }}
        />
        <h2>Hello, {username}</h2>
        <p>Would you like to make some changes?</p>

        <table className="setting-table p-2">
          <tbody>
            {/* Username Row */}
            <tr>
              <td>Username:</td>
              <td>
                {!isEditingUsername ? (
                  <>
                    {username}{' '}
                    <button
                      type="button"
                      onClick={() => setIsEditingUsername(true)}
                      style={{
                        background: 'none',
                        border: 'none',
                        padding: 0,
                        cursor: 'pointer',
                      }}
                      title="Edit Username"
                    >
                      <i className="bi bi-pencil p-3" style={{ color: 'grey', fontSize: '20px' }}></i>
                    </button>
                  </>
                ) : (
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      value={newUsername}
                      onChange={(e) => setNewUsername(e.target.value)}
                      className="form-control me-3 ms-3"
                    />
                    <button
                      type="button"
                      onClick={handleUsernameSave}
                      style={{
                        background: 'none',
                        border: 'none',
                        padding: 0,
                        cursor: 'pointer',
                        marginRight: '16px',
                      }}
                      title="Submit"
                    >
                      <i className="bi bi-check-circle" style={{ color: '#26995a', fontSize: '24px' }}></i>
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                          handleUsernameSave(false);
                        }}
                        style={{
                          background: 'none',
                          border: 'none',
                          padding: 0,
                          cursor: 'pointer',
                          marginRight: '16px',
                        }}
                        title="Cancel"
                      >
                        <i className="bi bi-x-circle" style={{ color: '#d9534f', fontSize: '24px' }}></i>
                      </button>
                  </div>
                )}
              </td>
            </tr>

            {/* Password Row */}
            {!isEditingPassword ? (
              <>
              <tr>
                <td className="text-nowrap fw-bold">Password:</td>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="me-2">********</span>
                    <button
                      type="button"
                      onClick={() => setIsEditingPassword(true)}
                      className="btn btn-link p-0 text-decoration-none"
                      title="Edit Password"
                    >
                      <i className="bi bi-pencil" style={{ color: 'grey', fontSize: '20px' }}></i>
                    </button>
                  </div>
                </td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <td className="text-nowrap fw-bold">Current Password:</td>
                  <td>
                    <input
                      type="password"
                      placeholder="********"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      className="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-nowrap fw-bold">New Password:</td>
                  <td>
                    <input
                      type="password"
                      placeholder="********"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-nowrap fw-bold">Confirm New Password:</td>
                  <td>
                    <input
                      type="password"
                      placeholder="********"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="form-control"
                    />
                  </td>
                </tr>
                <tr>
                <td></td>
                <td className="text-end">
                  <button
                    type="button"
                    onClick={handlePasswordSave}
                    style={{
                      background: 'none',
                      border: 'none',
                      padding: 0,
                      cursor: 'pointer',
                      marginRight: '16px',
                    }}
                    title="Submit"
                  >
                    <i className="bi bi-check-circle" style={{ color: '#26995a', fontSize: '24px' }}></i>
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setIsEditingPassword(false);
                      setCurrentPassword('');
                      setNewPassword('');
                      setConfirmPassword('');
                    }}
                    style={{
                      background: 'none',
                      border: 'none',
                      padding: 0,
                      cursor: 'pointer',
                      marginRight: '16px',
                    }}
                    title="Cancel"
                  >
                    <i className="bi bi-x-circle" style={{ color: '#d9534f', fontSize: '24px' }}></i>
                  </button>
                </td>
              </tr>

              </>
            )}

            {/* Mode Row */}
            <tr>
              <td>Mode:</td>
              <td>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="darkModeSwitch"
                    checked={mode === 'dark'}
                    onChange={() => handleModeToggle(mode === 'light' ? 'dark' : 'light')}
                  />
                  <label className="form-check-label" htmlFor="darkModeSwitch">
                    {mode === 'dark' ? 'Dark' : 'Light'}
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      {message && <p className="alert alert-info mt-3">{message}</p>}
    </div>
  );
};

export default SettingsPage;
